/*
| Developed by Dirupt
| Filename : yup.service.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import * as Yup from 'yup'
import getT from 'next-translate/getT'
// eslint-disable-next-line import/default
import i18n from '../../../i18n'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type FileFormat =
	| 'image/png'
	| 'image/jpeg'
	| 'image/jpg'
	| 'image/gif'
	| 'image/svg+xml'
	| 'image/webp'
	| 'image/heic'
	| 'image/heif'

/*
|--------------------------------------------------------------------------
| Class
|--------------------------------------------------------------------------
*/
export class YupService {
	// Public methods
	// ----------------------------------------------------------------------------

	/**
	 * Create new custom methods and inject locale
	 * @param currentLocale
	 */
	static async setup(currentLocale: string) {
		await this.createLocale(currentLocale)
	}

	// Custom methods
	// ----------------------------------------------------------------------------

	/**
	 * Test if file format is valid
	 * @param allowedFormats Array of allowed file formats
	 * @returns Yup test function that validates file format
	 */
	static testFileFormat(allowedFormats: Array<FileFormat>) {
		return (value?: File | null) => {
			if (!value) return true // Allow empty/null values
			return allowedFormats.includes(value.type as FileFormat)
		}
	}

	/**
	 * Test if file size is within limit
	 * @param maxSizeMB Maximum file size in megabytes
	 * @returns Yup test function that validates file size
	 */
	static testFileSize(maxSizeMB: number) {
		return (value?: File | null) => {
			if (!value) return true // Allow empty/null values
			const maxBytes = maxSizeMB * 1024 * 1024
			return value.size <= maxBytes
		}
	}

	// Public methods
	// ----------------------------------------------------------------------------

	/**
	 * Inject locale
	 * @param currentLocale
	 * @private
	 */
	private static async createLocale(currentLocale: string) {
		global.i18nConfig = i18n
		const t = await getT(currentLocale, 'common')

		Yup.setLocale({
			string: {
				uuid: t('yup.string.uuid'),
				url: t('yup.string.url'),
				email: t('yup.string.email'),
				min: ({ min }) => t('yup.string.min', { min }),
				max: ({ max }) => t('yup.string.max', { max }),
				length: ({ length }) => t('yup.string.length', { length }),
			},
			number: {
				integer: t('yup.number.integer'),
				positive: t('yup.number.positive'),
				negative: t('yup.number.negative'),
				min: ({ min }) => t('yup.number.min', { min }),
				max: ({ max }) => t('yup.number.max', { max }),
				lessThan: ({ less }) => t('yup.number.lessThan', { less }),
				moreThan: ({ more }) => t('yup.number.moreThan', { more }),
			},
			boolean: {
				isValue: ({ value }) => t('yup.boolean.isValue', { value }),
			},
			date: {
				min: ({ min }) => t('yup.date.min', { min }),
				max: ({ max }) => t('yup.date.max', { max }),
			},
			object: {
				noUnknown: t('yup.object.noUnknown'),
			},
			array: {
				min: ({ min }) => t('yup.array.min', { min }),
				max: ({ max }) => t('yup.array.max', { max }),
				length: ({ length }) => t('yup.array.length', { length }),
			},
			tuple: {
				notType: t('yup.tuple.notType'),
			},
			mixed: {
				notType: t('yup.mixed.notType'),
				required: t('yup.mixed.required'),
				oneOf: ({ values }) => t('yup.mixed.oneOf', { values }),
				notOneOf: ({ values }) => t('yup.mixed.notOneOf', { values }),
				default: t('yup.mixed.default'),
				defined: t('yup.mixed.defined'),
				notNull: t('yup.mixed.notNull'),
			},
		})
	}
}
